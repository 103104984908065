<template>
  <b-container class="py-3">
    <b-overlay :show="loading">
      <div class="body-title font-weight-bolder">Builds & Artifacts</div>
      <section class="d-flex py-2 align-items-center justify-content-start">
        <b-avatar
          class="bg-gray-200 text-body"
          size="3em"
          rounded
          :src="app.avatar_url"
          :text="app.title | truncate(2) | upperCase"
        />
        <div v-if="app.project_type" class="ml-3">
          <icon-apple
            v-if="app.project_type === 'ios'"
            fillColor="rgba(0, 0, 0, 0.60)"
          />
          <icon-android
            v-if="app.project_type === 'android'"
            fillColor="rgba(0, 0, 0, 0.60)"
          />
        </div>
        <div class="text-xl ml-3">/{{ app.path }}</div>
      </section>

      <list-build
        :items="items"
        @update="updateBuild"
        @push-route="onPushRoute"
      />

      <infinite-loading @infinite="infiniteHandler">
        <div slot="spinner"></div>
        <div slot="no-more"></div>
        <div slot="no-results">No results</div>
      </infinite-loading>

      <scroll-top-arrow />
      <router-view />
    </b-overlay>
  </b-container>
</template>

<script>
import ListBuild from "@/components/builds/ListBuilds";

export default {
  components: {
    ListBuild
  },
  props: {
    app_slug: {
      type: String
    }
  },
  data() {
    return {
      loading: null,
      isInit: false,
      isSignIn: false,
      app: {},
      items: [],
      pagination: {
        current_page: 1,
        per_page: 0,
        total_rows: 0
      },
      page: 1
    };
  },
  computed: {
    totalRows() {
      return this.pagination.total_rows;
    }
  },
  created() {
    this.loading = true;
    let checkGauthLoad = setInterval(() => {
      this.isInit = this.$gAuth.isInit;
      this.isSignIn = this.$gAuth.isAuthorized;

      if (this.isInit) {
        clearInterval(checkGauthLoad);
      }
    }, 1000);
  },
  watch: {
    isSignIn: "supervisor"
  },
  methods: {
    supervisor(isSignIn) {
      if (isSignIn) {
        this.getApp();
      } else {
        this.$store.dispatch("auth/logout");
        this.$router.push({ name: "auth.login" });
        this.loading = false;
      }
    },
    async infiniteHandler($state) {
      let options = null;

      if (!this.pagination.build_slug_next && this.page > 1) {
        $state.complete();
        return null;
      }

      if (this.pagination.build_slug_next && this.page > 1) {
        options = {
          build_slug_next: this.pagination.build_slug_next
        };
      }

      const { data, meta } = await this.getAll(options);

      if (data.length) {
        this.items.push(...data);
        this.pagination = meta;
        $state.loaded();
        this.page += 1;
      } else {
        $state.complete();
      }
    },
    async getApp() {
      this.loading = true;
      try {
        this.app = await this.$store.dispatch("app/app", {
          app_slug: this.app_slug
        });
      } catch (error) {
        console.error(error);
        if (error.response.status === 403) {
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "auth.login" });
        }
      } finally {
        this.loading = false;
      }
    },
    async getAll(options = null) {
      this.loading = true;
      try {
        return await this.$store.dispatch("app/builds", {
          app_slug: this.app_slug,
          options
        });
      } catch (error) {
        console.error(error);
        if (error.response.status === 403) {
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "auth.login" });
        }
      } finally {
        this.loading = false;
      }
    },
    async updateBuild(build_slug, data) {
      try {
        this.loading = true;
        const { message } = await this.$store.dispatch("app/updateBuild", {
          build_slug,
          data
        });

        this.$bvToast.toast(message, {
          title: "Success",
          toaster: "b-toaster-bottom-right",
          solid: true,
          variant: "success",
          appendToast: true
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message.message, {
          title: "Error",
          toaster: "b-toaster-bottom-right",
          solid: true,
          variant: "danger",
          appendToast: true
        });

        if (error.response.status === 403) {
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "auth.login" });
        }
      } finally {
        this.loading = false;
      }
    },
    onPushRoute(build_slug) {
      this.$router.push({
        name: "admin.artifacts",
        params: { app_slug: this.app_slug, build_slug }
      });
    },
    changePaginate(page) {
      if (page > this.pagination.current_page) {
        this.getAll({
          build_slug_next: this.pagination.build_slug_next,
          history: this.pagination.history.join(",")
        });
      } else {
        this.getAll({
          build_slug_next: this.pagination.build_slug_back,
          history: this.pagination.history.join(",")
        });
      }
    }
  }
};
</script>
